import React, { useEffect, useRef } from 'react';
import './About.css';
import * as THREE from 'three';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

const FounderCard = ({ name, role, image, description, id, delay }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleScroll = () => {
    const card = document.getElementById(id);
    const rect = card.getBoundingClientRect();
    if (rect.top <= window.innerHeight && rect.bottom >= 0) {
      setTimeout(() => {
        setIsVisible(true);
      }, delay);
    }
  };

  React.useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, );

  return (
    <div id={id} className={`founder-card ${isVisible ? 'visible' : ''}`} style={{ transitionDelay: `${delay}ms` }}>
      <img src={image} alt={name} />
      <h3>{name}</h3>
      <h4>{role}</h4>
      <p>{description}</p>
    </div>
  );
};

const About = () => {
  const threeContainer = useRef(null);

  useEffect(() => {
    let mouseX = 0, mouseY = 0;
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);

    threeContainer.current.appendChild(renderer.domElement);

    const renderPass = new RenderPass(scene, camera);
    const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 15, 4, 0.85);
    bloomPass.threshold = 0.1;
    bloomPass.strength = 2.5;
    bloomPass.radius = 0.25;

    const composer = new EffectComposer(renderer);
    composer.addPass(renderPass);
    composer.addPass(bloomPass);

    const blurShader = {
      uniforms: {
        "tDiffuse": { value: 5 },
        "uBlur": { value: 10.0 }
      },
      vertexShader: `
        varying vec2 vUv;
        void main() {
          vUv = uv;
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }
      `,
      fragmentShader: `
        uniform sampler2D tDiffuse;
        uniform float uBlur;
        varying vec2 vUv;
    
        float gaussian(float x, float sigma) {
          return exp(-(x * x) / (2.0 * sigma * sigma)) / (2.0 * 3.141592 * sigma * sigma);
        }
    
        void main() {
          vec4 sum = vec4(0.0);
          float total = 2.0;
          float sigma = uBlur * 0.5;
    
          for (int i = -2; i <= 2; i++) {
            for (int j = -2; j <= 2; j++) {
              float weight = gaussian(length(vec2(float(i), float(j))), sigma);
              sum += texture2D(tDiffuse, vUv + vec2(float(i), float(j)) * 0.002) * weight;
              total += weight;
            }
          }
    
          gl_FragColor = sum / total;
        }
      `
    };
    

    const blurPass = new ShaderPass(blurShader);
    blurPass.uniforms["uBlur"].value = 0.00001;
    composer.addPass(blurPass);

    renderer.setSize(window.innerWidth, window.innerHeight);

    threeContainer.current.appendChild(renderer.domElement);

    // Particles
    const geometry = new THREE.BufferGeometry();
    const vertices = [];
    const sizes = [];
    const colors = [];

    for (let i = 0; i < 10000; i++) {
      const x = (Math.random() - 0.5) * 2000;
      const y = (Math.random() - 0.5) * 2000;
      const z = (Math.random() - 0.5) * 2000;
      vertices.push(x, y, z);
      sizes.push(Math.random() * 100);
      // Color control: Blue, Purple, Pink
      const color = new THREE.Color();
      const colorOptions = [
        0x0000FF, // Blue
        0x00FFC8, // Green
        0x00F0FF, // Blue
        0x800080, // Purple
        0xFC73C9  // Pink
      ];
      color.setHex(colorOptions[Math.floor(Math.random() * colorOptions.length)]);
      colors.push(color.r, color.g, color.b);
      }

    geometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3));
    geometry.setAttribute('size', new THREE.Float32BufferAttribute(sizes, 1));
    geometry.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

    const material = new THREE.PointsMaterial({ size: 7, vertexColors: true, sizeAttenuation: true });
    const particles = new THREE.Points(geometry, material);
    scene.add(particles);

    camera.position.z = 1000;

    const onDocumentMouseMove = (event) => {
      mouseX = (event.clientX / window.innerWidth) * 3 - 2;
      mouseY = -(event.clientY / window.innerHeight) * 3 + 2;
    };

    document.addEventListener('mousemove', onDocumentMouseMove, false);

    const animate = () => {
      requestAnimationFrame(animate);

      particles.rotation.x += 0.0001;
      particles.rotation.y += 0.0001;

      // Smooth Scroll and Mouse move
      particles.rotation.x += mouseY * 0.0002;
      particles.rotation.y += mouseX * 0.0002;

      composer.render();
    };

      // Create a WebGL renderer for the shader
      const shaderRenderer = new THREE.WebGLRenderer({ alpha: true });
      shaderRenderer.setSize(window.innerWidth, window.innerHeight);

      // Create a new scene and camera for the shader
      const shaderScene = new THREE.Scene();
      const shaderCamera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);
      shaderCamera.position.z = 1;

      // Define the custom shader material
      const shaderMaterial = new THREE.ShaderMaterial({
        uniforms: {
          time: { value: 0.0 },
        },
        transparent: true,

        fragmentShader: `
        uniform float time;
        uniform float cardID;
        varying vec2 vUv;
      
        float random(vec2 st, float seed) {
          return fract(sin(dot(st.xy, vec2(12.9898, 78.233) + seed)) * 43758.5453123);
        }
      
        void main() {
          vec2 uv = vUv * 10.0;
          float randSeed = random(uv, time + cardID);  // Unique for each card
      
          // More complex wave function for richer visual pattern
          float x = uv.x + randSeed * 0.5;
          float y = uv.y + randSeed * 0.5;
          float wave = sin(x * 2.0 * time) * cos(y * 2.0 * time);
          float blendWave = smoothstep(-0.5, 0.5, wave);
      
          // Dynamic color shifting effect
          vec3 color1 = vec3(sin(time) * 0.5 + 0.5, 0.0, cos(time) * 0.5 + 0.5);  // Dynamic Blue
          vec3 color2 = vec3(0.5, sin(time + 1.0) * 0.5 + 0.5, 0.5);              // Dynamic Purple
          vec3 color3 = vec3(1.0, 0.0, sin(time + 2.0) * 0.5 + 0.5);              // Dynamic Pink
      
          vec3 finalColor = mix(color1, color2, blendWave);
          finalColor = mix(finalColor, color3, 15.0 - blendWave);
      
          // Enhanced noise for more organic look
          float noise = random(vUv, time) * 0.75;
          finalColor += noise * finalColor;
      
          gl_FragColor = vec4(finalColor, 1);  // Increased alpha for more pronounced effect
        }
      `,
      


      });

      // Create mesh and add it to the shader scene
      const shaderGeometry = new THREE.PlaneGeometry(2, 2);
      const shaderMesh = new THREE.Mesh(shaderGeometry, shaderMaterial);
      shaderScene.add(shaderMesh);

      // Insert the shader canvas into each .founder-card
      document.querySelectorAll('.founder-card').forEach((card) => {
        const newShaderRenderer = new THREE.WebGLRenderer({ alpha: true });
        newShaderRenderer.setSize(window.innerWidth, window.innerHeight);
        card.appendChild(newShaderRenderer.domElement);
        const newShaderMaterial = shaderMaterial.clone();
        const newShaderMesh = new THREE.Mesh(shaderGeometry, newShaderMaterial);
        shaderScene.add(newShaderMesh);

        const animateNewShader = () => {
          requestAnimationFrame(animateNewShader);
          newShaderMaterial.uniforms.time.value += 0.01;
          newShaderRenderer.render(shaderScene, shaderCamera);
        };
        animateNewShader();
      });

    animate();
  }, []);

  return (
      <div className="about">
      <div className="background"></div>  {/* Background layer */}
      <div ref={threeContainer} className="three-container" />  {/* Three.js container */}
      <div className="nonBlurTitle">About Me</div>  {/* Non-blurred title */}
      <div className="content">
      <div class="company-info">
        <p>
          I'm building a healthcare technology platform to deliver machine learning-derived polygenic models to clinicians in order to create a new generation of preventive healthcare.
        </p>
      </div>
        <div className="founder-container">
        <FounderCard
          id="founder-2"
          name="Scott Sauers"
          role=""
          image="./cropped-Scott_remove.png"
          description="I'm working on Alzheimer's disease at Washington University, and polygenic screenings for dozens of different diseases at PolyCypher Health. I previously worked in neuropsychological diagnostics. I enjoy genomic machine learning methods."
          delay={10}
        />
      </div>
      </div>
    </div>
  );
};

export default About;