import React, { useRef, useState, useEffect } from 'react';
import { Canvas, useFrame, useThree, extend } from '@react-three/fiber';
import { Text } from '@react-three/drei';
import { useSpring, animated } from '@react-spring/three';
import { ShaderMaterial } from 'three';
import { shaderMaterial } from '@react-three/drei';

// Define the shader material
const ColorShiftMaterial = shaderMaterial(
  { time: 0, resolution: [0, 0] },
  // vertex shader
  `void main() {
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    // Apply skew transformation for italic effect
    mvPosition.x += mvPosition.y * 0.3; // Skewing based on the Y position
    gl_Position = projectionMatrix * mvPosition;
  }`,
  // fragment shader
  `uniform float time;
  uniform vec2 resolution;
  void main() {
    vec2 uv = gl_FragCoord.xy / resolution.xy;
  
    // Creating a smoother, organic, and evolving grain effect
    float grainIntensity = 1.5;
    vec2 grainUV = uv * grainIntensity + time; // Adding time to grainUV for motion
    float noise = fract(sin(dot(grainUV, vec2(12.9898, 78.233))) * 43758.5453);
    noise = smoothstep(0.4, 0.6, noise); // Smooth transition for noise
  
    // Enhancing color with a smoother blend and more variation
    vec3 color = 0.7 * vec3(1.0, 1.0, 1.0) * noise; // Soft white noise
    color += 0.6 * cos(time + uv.xyx + vec3(0, 6, 12)); // Colorful shifting effect
    color = pow(color, vec3(1.2)); // Gamma correction for a brighter look
  
    // Blending colors more smoothly
    vec3 finalColor = mix(vec3(0.1, 0.2, 0.3), color, noise); // Blending with base color
  
    gl_FragColor = vec4(finalColor, 1.0);
  }`
);

extend({ ColorShiftMaterial });

function ParallaxText() {
  const textRef = useRef();
  const { viewport, size } = useThree();
  const [props, set] = useSpring(() => ({
    rotation: [0, 0, 0],
    config: { mass: 5, tension: 350, friction: 40 }
  }));

  // Create a state to hold the shader material
  const [material] = useState(() => new ColorShiftMaterial());

  useFrame((state, delta) => {
    // Update shader time
    material.uniforms.time.value += delta;

    // Update shader resolution
    material.uniforms.resolution.value = [size.width, size.height];

    // Adjust the rotation for a 3D depth effect
    const xRot = (state.pointer.y + 2) * Math.PI; // Rotate around the x-axis
    const yRot = state.pointer.x * Math.PI; // Rotate around the y-axis

    set({
      rotation: [(1 * xRot), (1 * yRot), 0],
    });
  });

  return (
    <animated.group ref={textRef} rotation={props.rotation}>
      <Text
        material={material}
        fontSize={0.3}
        maxWidth={200}
        lineHeight={1}
        letterSpacing={0.02}
        textAlign={'center'}
        anchorX='center'
        anchorY='middle'
      >
        . . . through computational methods.
      </Text>
    </animated.group>
  );
}

function ParallaxText3D() {
    return (
      <Canvas style={{ 
          position: 'absolute',
          top: '70%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100vw',
          height: '100vh',
          zIndex: 10,
        }}>
        <ParallaxText />
      </Canvas>
    );
}

export default ParallaxText3D;