import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CustomWaitingList.css';
import DnaMatrixBackground from './DnaMatrixBackground';




const useLoadingText = (isLoading) => {
  const [loadingText, setLoadingText] = useState('Loading');

  useEffect(() => {
    if (!isLoading) return;

    const interval = setInterval(() => {
      setLoadingText(prevText => prevText + '.' === 'Loading....' ? 'Loading' : prevText + '.');
    }, 500);

    return () => clearInterval(interval);
  }, [isLoading]);

  return loadingText;
};

const WaitingList = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [responseMessage, setResponseMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const loadingText = useLoadingText(isLoading);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post('https://uebp8qa1ge.execute-api.us-east-2.amazonaws.com/add', { email, message });
      setResponseMessage(response.status === 200 ? 'Successfully added to the waiting list! 🎉' : 'An error occurred while adding to the waiting list.');
      setMessage('');
    } catch (error) {
      console.error('There was an error sending the request', error);
      setResponseMessage('An error occurred while adding to the waiting list.');
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <div className="container mt-5" style={{ fontFamily: 'Helvetica, Roboto, sans-serif' }}>
      <DnaMatrixBackground />
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="text-center">
            <div className="d-flex flex-column align-items-center m-auto">
              <div className="logoWrapper">
                <img src={`${process.env.PUBLIC_URL}/logo512.png`} alt="Logo" className="logoWithEffects" style={{ maxWidth: '110px', maxHeight: '100px' }} />
              </div>
              <div className="titleWrapper">


              <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '150px', // Adjust height as needed to prevent crowding
                backgroundColor: 'rgba(0, 0, 0, 0.0)',
                borderRadius: '10px',
                margin: '20px 0', // Adds spacing above and below the title
              }}>
                <h1 style={{
                  fontSize: '2.5rem', // Large font size for visibility
                  color: '#FFFFFF', // White color for the text
                  padding: '20px 40px', // Padding to ensure text is not touching the edges
                  margin: 0, // Remove default margin from h1
                  fontWeight: '900', // Lighter font-weight for a modern, clean look
                  letterSpacing: '1px' // Spacing between letters for readability
                }}>
                  <b>Contact</b>
                </h1>
              </div>




              </div>
            </div>
          </div>
          <div className="container" style={{ display: 'flex', justifyContent: 'center'}}>
          <div className="" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '15px' }}> {/* Making the card background translucent with rounded corners */}
            <div className="card-header">
              <h3>Send a message</h3>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="email-react">Email:</label>
                  <input type="email" id="email-react" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
                <div className="form-group">
                  <label htmlFor="message-react">Message:</label>
                  <textarea id="message-react" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} />
                </div>
                <button type="submit" className="btn btn-primary">Submit</button>
              </form>
              <div className="response-message">
                {isLoading ? loadingText : responseMessage}
              </div>
            </div>
          </div>
          </div>

          <div className="text-center mt-20">
  <div className="logoWhite">
    <img 
      src={`${process.env.PUBLIC_URL}/white_logo512.png`} 
      alt="White Logo" 
      style={{ 
        maxWidth: '55px', 
        maxHeight: '50px', 
        width: 'auto', 
        height: 'auto', 
        display: 'block', 
        margin: '0 auto' 
      }} 
    />
  </div>
</div>

        </div>
      </div>
    </div>
  );
};

export default WaitingList;