import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { GlobalWorkerOptions } from 'pdfjs-dist';
import { NavLink } from 'react-router-dom';

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js`;

const CircadianRhythm = () => {
  return (
    <div>
      <Worker workerUrl={GlobalWorkerOptions.workerSrc}>
        <div
          style={{
            height: '750px',
            width: '100%',
            border: '1px solid rgba(0, 0, 0, 0.3)',
          }}
        >
          <Viewer fileUrl="/zpae023.pdf" />
        </div>
      </Worker>
    </div>
  );
};

const NeurodegenerativeDisease = () => <div>Coming Soon</div>;
const MultiModelBenchmarking = () => <div>Coming Soon</div>;
const Psychoplastogens = () => <div>Coming Soon</div>;
const PolygenicAI = () => <div>Coming Soon</div>;
const BioinformaticsConsulting = () => <div>Coming Soon</div>;
const SoftwareDevelopment = () => <div>Coming Soon</div>;
const Tutoring = () => <div>Coming Soon</div>;
const ScientificAutomation = () => <div>Coming Soon</div>;
const DataEngineering = () => <div>Coming Soon</div>;

const NavigationMenu = () => {
  const styles = {
    dropdownItem: {
      padding: '10px',
      textDecoration: 'none',
      color: 'black',
      display: 'block',
    }
  };

  return (
    <ul>
      <li style={styles.dropdownItem}><NavLink to="/sleep">Circadian rhythm</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/disease">Neurodegenerative disease</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/benchmark">Multi-model benchmarking</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/psychoplastogens">Psychoplastogens</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/polygenic">Polygenic AI</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/consulting">Bioinformatics Project Consulting</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/software">Software Development</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/tutoring">Tutoring</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/automation">Scientific Automation</NavLink></li>
      <li style={styles.dropdownItem}><NavLink to="/data">Data Engineering</NavLink></li>
    </ul>
  );
};

export {
  CircadianRhythm,
  NeurodegenerativeDisease,
  MultiModelBenchmarking,
  Psychoplastogens,
  PolygenicAI,
  BioinformaticsConsulting,
  SoftwareDevelopment,
  Tutoring,
  ScientificAutomation,
  DataEngineering,
  NavigationMenu,
};