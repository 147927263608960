import React, { useState, useEffect } from 'react';
import * as d3 from 'd3';
import { CircularProgress } from '@mui/material';
import './Dashboard.css';
import { useCognito } from './CognitoContext';

const titleMapping = {
    'PGS003725': 'Coronary Artery Disease',
    'PGS03487344': 'Test Score Name 2',
    'PGS03344': 'Test PGS 3',
    'PGS000921': 'BMI',
    'PGS002724': 'Ischaemic Stroke',
    'PGS000898': 'Alzheimer\'s Disease',
    'PGS000758': 'Height'
  };


const processJSONData = (json) => {
  // Extract the necessary information for percentiles and histograms
  const percentiles = [];
  const histogramData = [];

  Object.entries(json).forEach(([pgsId, data]) => {
    const baseId = pgsId.split('_')[0];
    const title = titleMapping[baseId] || baseId; // Use the mapped name or default to the baseId if not found
    const referenceZs = data.reference_samples.map(sample => sample.Z_norm2);
    const pipelinePercentiles = data.pipeline_samples.map(pipelineSample => {
      const zScore = pipelineSample.Z_norm2;
      const percentile = calculatePercentile(zScore, referenceZs);
      return { ...pipelineSample, title, percentile, zScore };
    });

    percentiles.push(...pipelinePercentiles);
    histogramData.push({ title, referenceZs, pipelinePercentiles });
  });

  return { percentiles, histogramData };
};

const calculatePercentile = (value, dataset) => {
  const rank = dataset.filter(v => v < value).length;
  const count = dataset.length;
  return (rank / count) * 100;
};

const createHistogram = (data, element, userZScore) => {
    // Clear the previous SVG content
    d3.select(element).selectAll('*').remove();
  
    // Set up SVG dimensions and margins
    const margin = { top: 10, right: 30, bottom: 30, left: 40 };
    const width = 460 - margin.left - margin.right;
    const height = 340 - margin.top - margin.bottom;
  
    // Create the SVG container
    const svg = d3.select(element)
                  .attr('width', width + margin.left + margin.right)
                  .attr('height', height + margin.top + margin.bottom)
                  .append('g')
                  .attr('transform', `translate(${margin.left},${margin.top})`);
  
    // Set up the x-axis scale
    const xScale = d3.scaleLinear()
                     .domain([-4, 4]) // Adjusted domain to match the "pretty" code
                     .range([0, width]);
  
    // Update the x-axis and add the label "Polygenic Score"
    svg.append('g')
        .attr('transform', `translate(0,${height})`)
        .call(d3.axisBottom(xScale).tickFormat(d => d).tickSize(0))
        .append("text")
        .attr("class", "label")
        .attr("x", width / 2)
        .attr("y", 25) // Move the text up by changing the y attribute
        .style("text-anchor", "middle")
        .style("fill", "black") // Set text color to black
        .style("font-size", "12px") // Increase font size
        .text("Polygenic Score");
 
  
    // Generate the histogram data
    const histogram = d3.histogram()
                        .value(d => d)
                        .domain(xScale.domain())
                        .thresholds(xScale.ticks(40))
                        (data);
  
    // Set up the y-axis scale
    const yScale = d3.scaleLinear()
                     .domain([0, d3.max(histogram, d => d.length)])
                     .range([height, 0]);
  
    // Create the color scale
    const colorScale = d3.scaleLinear()
                         .domain([-4, 0, 4])
                         .range(["green", "blue", "red"]);
  
    // Draw the bars
    svg.selectAll('.bar')
       .data(histogram)
       .enter().append('g')
       .attr('class', 'bar')
       .attr('transform', d => `translate(${xScale(d.x0)},${yScale(d.length)})`)
       .append('rect')
       .attr('x', 1)
       .attr('width', d => xScale(d.x1) - xScale(d.x0) - 1)
       .attr('height', d => height - yScale(d.length))
       .attr('fill', d => colorScale(d.x0));
  
    // Draw user's z-score line
    if (userZScore !== undefined) {
      svg.append('line')
         .attr('x1', xScale(userZScore))
         .attr('x2', xScale(userZScore))
         .attr('y1', 0)
         .attr('y2', height)
         .attr('stroke', 'gold')
         .attr('stroke-width', 2);
  
      svg.append('polygon')
         .attr('points', `${xScale(userZScore) - 5},${height} ${xScale(userZScore) + 5},${height} ${xScale(userZScore)},${height - 10}`)
         .attr('fill', 'gold');
    }
  
    // Add text "higher risk" at the right of the histogram
    svg.append("text")
       .attr("x", width)
       .attr("y", margin.top + 20)
       .attr("text-anchor", "end")
       .style("fill", "red")
       .style("font-weight", "bold")
       .text("Higher risk");
  
    // Add text "lower risk" at the left of the histogram
    svg.append("text")
       .attr("x", 0)
       .attr("y", margin.top + 20)
       .attr("text-anchor", "start")
       .style("fill", "green")
       .style("font-weight", "bold")
       .text("Lower risk");
  };  

const App = () => {
  const { email, loading } = useCognito();  // Destructure loading from useCognito

  const [percentiles, setPercentiles] = useState([]);
  const [histogramData, setHistogramData] = useState([]);
  const [loadingapp, setloadingapp] = useState(false);
  const [error, setError] = useState('');
  
  const getDescription = (pgsId, percentile) => {
    let description = '';
    switch (pgsId) {
      case 'PGS003725': // Coronary Artery Disease
        description = `This means your estimated genetic predisposition to coronary artery disease is 
          ${percentile < 50 ? `lower than ${(100 - percentile).toFixed(2)}` : `higher than ${percentile.toFixed(2)}`}%
          of people in a healthy reference population. This score is based on a reference population and indicates the relative genetic risk associated with the disease. A higher percentile suggests a higher estimated genetic risk. Lifestyle and environmental factors can also influence the overall risk. This score is based on a 2023 study which used multi-ancestry genome-wide association data for over a million people and was shown to be associated with coronary artery disease. In the study’s sample, people with higher percentiles were more likely to have coronary artery disease.`;
        break;
      case 'PGS03487344': // Test Score Name 2
        description = `Example text for Test Score Name 2.`;
        break;
      case 'PGS03344': // Test PGS 3
        description = `Example text for Test PGS 3.`;
        break;
      default:
        description = `General description for other scores.`;
    }
    return description;
  };  


  useEffect(() => {
    // Render histograms once the data is loaded
    histogramData.forEach((data, index) => {
      const svgId = `svg-${index}`;
      const userZScore = data.pipelinePercentiles[0]?.zScore;
      createHistogram(data.referenceZs, `#${svgId}`, userZScore);
    });
  }, [histogramData]);

  const handleFileChange = async (event) => {
    setloadingapp(true);
    setError('');

    try {
      const file = event.target.files[0];
      if (file) {
        const text = await file.text();
        const json = JSON.parse(text);
        const processedData = processJSONData(json);
        setPercentiles(processedData.percentiles);
        setHistogramData(processedData.histogramData);
      }
    } catch (err) {
      setError(`An error occurred while processing the file: ${err.message}`);
    } finally {
      setloadingapp(false);
    }
  };

  if (loadingapp) {
    return <CircularProgress />;
  }

  const emailBase = email ? email.split('@')[0] : '';
  // Show a Material-UI CircularProgress while loading
  if (loading) {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
        </div>
    );
}

return (
  <div className="example-report">
    <div className="header">
      <h1>PolyCypher Precision Health Report <small><i>(example)</i></small></h1>
      <h3>Welcome, {emailBase}!</h3>
    </div>
    <div className="report-content">
      <input type="file" onChange={handleFileChange} />
      {error && <div className="error">{error}</div>}
      {percentiles.map((percentileData, index) => {
        // Extract the PGS ID from the title using the titleMapping
        const pgsId = Object.keys(titleMapping).find(key => titleMapping[key] === percentileData.title) || percentileData.pgsId;
        // Use the pgsId to get the description, ensuring that we have the correct mapping
        const description = getDescription(pgsId, percentileData.percentile);

        return (
          <section key={index}>
            <h2>For {percentileData.title}, your percentile is: <span style={{ fontWeight: 'bold', textDecoration: 'underline' }}>{percentileData.percentile.toFixed(2)}%</span></h2>
            <p className="predisposition-info">
              {description}
            </p>
            <a href={`#study-${percentileData.title.replace(/\s+/g, '-')}`}>Link to study</a>
            <svg id={`svg-${index}`} width="460" height="400"></svg>
          </section>
        );
      })}
    </div>
    <div className="footer">
      PolyCypher Health Polygenic Score Report
    </div>
  </div>
);

};

export default App;