import React, { useState, useEffect, useRef } from 'react';
import './Placeholder.css';
import axios from 'axios';
import './CustomWaitingList.css';
import debounce from 'lodash/debounce';

import FlowFieldAnimation from './flow';



// Placeholder component for Contact
const Contact = () => {
    const [cardPositions, setCardPositions] = useState([]);
  
  
  
    const useLoadingText = (isLoading) => {
      const [loadingText, setLoadingText] = useState('Loading');
    
      useEffect(() => {
        if (!isLoading) return;
    
        const interval = setInterval(() => {
          setLoadingText(prevText => prevText + '.' === 'Loading....' ? 'Loading' : prevText + '.');
        }, 500);
    
        return () => clearInterval(interval);
      }, [isLoading]);
    
      return loadingText;
    };
    
      const [email, setEmail] = useState('');
      const [message, setMessage] = useState('');
      const [responseMessage, setResponseMessage] = useState('');
      const [isLoading, setIsLoading] = useState(false);
      const loadingText = useLoadingText(isLoading);
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
    
        try {
          const response = await axios.post('https://uebp8qa1ge.execute-api.us-east-2.amazonaws.com/add', { email, message });
          setResponseMessage(response.status === 200 ? 'Message sent! 🎉' : 'An error occurred.');
          setMessage('');
        } catch (error) {
          console.error('There was an error sending the request', error);
          setResponseMessage('An error occurred.');
        } finally {
          setIsLoading(false);
        }
      };
  
      const updateCardPositions = () => {
        // Select all card elements
        const cards = document.querySelectorAll('.text-center');
      
        const positions = Array.from(cards).map(card => {
          const rect = card.getBoundingClientRect();
          return {
            x: rect.left + window.scrollX,
            y: rect.top + window.scrollY,
            width: rect.width,
            height: rect.height
          };
        });
    
        // Only update state if positions have actually changed
        setCardPositions(prevPositions => {
          if (JSON.stringify(prevPositions) !== JSON.stringify(positions)) {
            return positions;
          }
          return prevPositions;
        });
      };
    
      // Debounced version of the update function
      const debouncedUpdateCardPositions = debounce(updateCardPositions, 100);
    
      useEffect(() => {
        window.addEventListener('resize', debouncedUpdateCardPositions);
        
        // Update card positions after the layout has likely stabilized
        setTimeout(updateCardPositions, 500);
    
        return () => {
          window.removeEventListener('resize', debouncedUpdateCardPositions);
        };
      }, []);
    
      // Function to get corner coordinates for each card
      const getCorners = (card) => [
        { x: card.x, y: card.y },
        { x: card.x + card.width, y: card.y },
        { x: card.x, y: card.y + card.height },
        { x: card.x + card.width, y: card.y + card.height }
      ];

      return (
        <div className="container mt-5" style={{ fontFamily: 'Helvetica, Roboto, sans-serif' }}>
  
          <FlowFieldAnimation
            className="flow-background"
            cardPositions={cardPositions}
            cornerPositions={cardPositions.flatMap(card => getCorners(card))}
            />

            <div className="row">
            <div className="col-md-6 offset-md-3">
              {/* Logo and Title */}
              <div className="text-center">
                <h1 style={{ color: '#FFFFFF', fontWeight: 'bold', letterSpacing: '1px', fontSize: '2.5rem' }}>Contact Form</h1>
              </div>

              {/* Contact Form */}
              <div className="container" style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="card1" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', borderRadius: '15px', minWidth: '200%', maxWidth: '200%' }}>
                  <div className="card-header">
                    <h3>Send a message!</h3>
                  </div>
                  <div className="card-body">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="email-react">Email:</label>
                        <input type="email" id="email-react" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                      </div>
                      <div className="form-group">
                        <label htmlFor="message-react">Message:</label>
                        <textarea id="message-react" className="form-control" value={message} onChange={(e) => setMessage(e.target.value)} />
                      </div>
                      {/* <div className="text-center">*/}
                      <button type="submit" className="btn btn-primary">Submit</button>
                      {/* </div>*/}
                    </form>
                    <div className="response-message">
                      {isLoading ? loadingText : responseMessage}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };

export default Contact;