import React, { useRef, useEffect } from 'react';
import './Home.css';
import debounce from 'lodash/debounce';
import ParallaxText3D from './Uncover';
import * as d3 from 'd3';
import FlowFieldAnimation from './perlin';

const nodes = [
    { id: "Technology", group: "title", size: 40 },
    { id: "PolyCypher Health", url: "https://polycypherhealth.com", desc: "What we work on", group: "Technology", size: 20 },
    { id: "Machine learning projects", url: "https://github.com/ScottSauers?tab=repositories", desc: "View data science and machine learning projects", group: "Technology", size: 20 },
    { id: "Genomic algorithms", url: "/ML", desc: "", group: "Technology", size: 20 },
    { id: "Academic", group: "title", size: 40 },
    { id: "Chronotype project", url: "https://doi.org/10.1093/sleepadvances/zpae023", desc: "What's so important about the timing of your sleep?", group: "Academic", size: 20 },
    { id: "Psychoplastogens and neurodegeneration", url: "https://drive.google.com/drive/folders/121b4VXs55D4znwVHbmaRR9b-PH7MFl0i?usp=sharing", desc: "Brain plasticity modulation", group: "Academic", size: 20 },
    { id: "Science of genomics", url: "/science", desc: "", group: "Academic", size: 20 },
    { id: "Creative", group: "title", size: 40 },
    { id: "Art", url: "/art", desc: "", group: "Creative", size: 20 },
    { id: "Music", url: "https://open.spotify.com/artist/1fUYyyhqHLIQE0a1qwJ28N?si=A8kyp0QjSlu9iW48l0-UCg", desc: "Morris", group: "Creative", size: 20 },
    { id: "Writings", url: "https://medium.com/@ScottSauers", desc: "", group: "Creative", size: 20 },
];

const links = [
    { source: "Technology", target: "PolyCypher Health" },
    { source: "Technology", target: "Machine learning projects" },
    { source: "Technology", target: "Genomic algorithms" },
    { source: "Academic", target: "Chronotype project" },
    { source: "Academic", target: "Psychoplastogens and neurodegeneration" },
    { source: "Academic", target: "Science of genomics"},
    { source: "Creative", target: "Art" },
    { source: "Creative", target: "Music" },
    { source: "Creative", target: "Writings" },
];

const PolyCypherPage = () => {
    const updateCardPositions = () => {
        const cards = document.querySelectorAll('.st-card, .st-card');

        const positions = Array.from(cards).map(card => {
            const rect = card.getBoundingClientRect();
            return {
                x: rect.left + window.scrollX,
                y: rect.top + window.scrollY,
                width: rect.width,
                height: rect.height
            };
        });
    };

    const debouncedUpdateCardPositions = debounce(updateCardPositions, 100);

    useEffect(() => {
        window.addEventListener('resize', debouncedUpdateCardPositions);
        setTimeout(updateCardPositions, 500);
        return () => {
            window.removeEventListener('resize', debouncedUpdateCardPositions);
        };
    }, []);

    const sectionRef = useRef(null);

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (sectionRef.current) {
                const { top, bottom, height } = sectionRef.current.getBoundingClientRect();
                let opacityTop;

                if (e.clientY < top) {
                    opacityTop = 1;
                } else if (e.clientY > bottom) {
                    opacityTop = 0;
                } else {
                    const distanceFromTop = e.clientY - top;
                    opacityTop = Math.max(0, Math.min(1, 1 - (0.4 * distanceFromTop / height)));
                }

                sectionRef.current.style.background = `linear-gradient(rgba(41, 0, 51, ${opacityTop}), rgba(41, 0, 51, 1)), url("your-background-image.jpg")`;
            }
        };

        window.addEventListener('mousemove', handleMouseMove);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('visualization-container');
        const width = container.clientWidth;
        const height = container.clientHeight;

        const svg = d3.select("#visualization-container")
            .append("svg")
            .attr("width", width)
            .attr("height", height);

        const flowField = svg.append("defs")
            .append("filter")
            .attr("id", "flowField");

        flowField.append("feTurbulence")
            .attr("type", "fractalNoise")
            .attr("baseFrequency", "0.01")
            .attr("numOctaves", "3")
            .attr("result", "turbulence");

        flowField.append("feDisplacementMap")
            .attr("in", "SourceGraphic")
            .attr("in2", "turbulence")
            .attr("scale", "30")
            .attr("xChannelSelector", "R")
            .attr("yChannelSelector", "G");

        const simulation = d3.forceSimulation(nodes)
            .force("link", d3.forceLink(links).id(d => d.id).distance(100))
            .force("charge", d3.forceManyBody().strength(-200))
            .force("center", d3.forceCenter(width / 2, height / 2))
            .force("collision", d3.forceCollide().radius(d => d.size + 10))
            .force("x", d3.forceX().strength(0.05).x(width / 2))
            .force("y", d3.forceY().strength(0.05).y(height / 2));

        const link = svg.append("g")
            .selectAll("line")
            .data(links)
            .enter()
            .append("line")
            .attr("stroke", "#ccc")
            .attr("stroke-width", 1.5);

        const node = svg.append("g")
            .selectAll("g")
            .data(nodes)
            .enter()
            .append("g")
            .call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragended));

        node.append("circle")
            .attr("r", d => d.size)
            .attr("fill", d => d.group === "title" ? "#800080" : "#6a0dad")
            .attr("stroke", "#fff")
            .attr("stroke-width", "1.5px")
            .attr("filter", "url(#flowField)")
            .on("mouseover", function (event, d) {
                const textWidth = Math.max(
                    d3.select(this.parentNode).select("text.main-text").node().getComputedTextLength(),
                    d.desc ? d3.select(this.parentNode).select("text.desc-text").node().getComputedTextLength() : 0
                );

                const expandedSize = Math.max(d.size, textWidth / 2 + 10);

                d3.select(this)
                    .transition()
                    .duration(300)
                    .attr("r", expandedSize);  // Expand the circle smoothly

                d3.select(this.parentNode)
                    .selectAll("text")
                    .transition()
                    .duration(300)
                    .style("opacity", 1);  // Fade in the text smoothly
            })
            .on("mouseout", function (event, d) {
                d3.select(this)
                    .transition()
                    .duration(300)
                    .attr("r", d.size);  // Shrink the circle smoothly

                if (d.group !== "title") {
                    d3.select(this.parentNode)
                        .selectAll("text")
                        .transition()
                        .duration(300)
                        .style("opacity", 0);  // Fade out the text smoothly
                }
            })
            .on("click", (event, d) => {
                if (d.url) window.open(d.url, "_blank");
            });

        node.append("text")
            .attr("class", "main-text")
            .attr("text-anchor", "middle")
            .attr("dy", d => d.desc ? "-0.3em" : "0.3em")
            .style("font-family", "Arial, sans-serif")
            .style("font-size", d => d.group === "title" ? "14px" : "10px")
            .style("pointer-events", "none")
            .style("fill", "#fff")
            .style("opacity", d => d.group === "title" ? 1 : 0)
            .text(d => d.id);

        node.append("text")
            .attr("class", "desc-text")
            .attr("text-anchor", "middle")
            .attr("dy", "1.3em")
            .style("font-family", "Arial, sans-serif")
            .style("font-size", "10px")
            .style("pointer-events", "none")
            .style("fill", "#fff")
            .style("opacity", 0)
            .text(d => d.desc);

        simulation.on("tick", () => {
            link
                .attr("x1", d => d.source.x)
                .attr("y1", d => d.source.y)
                .attr("x2", d => d.target.x)
                .attr("y2", d => d.target.y);

            node.attr("transform", d => `translate(${d.x},${d.y})`);
        });

        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }

        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }

        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }

        function randomizeNodePosition() {
            nodes.forEach(node => {
                if (node.group === "title") {
                    node.vx += (Math.random() - 0.5) * 2;
                    node.vy += (Math.random() - 0.5) * 2;
                }
            });
            simulation.alpha(0.1).restart();
        }

        setInterval(randomizeNodePosition, 1000);
    }, []);

    return (
        <div>
            <div className="leadblock">
                <div className="leadblock-content">
                    <p className="leadblock-title">
                        <s></s>Explore&nbsp;
                        <span className="title-emphasis">
                            <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;science</span>
                            <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;data analysis&nbsp;&nbsp; </span>
                            <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;system modeling&nbsp;&nbsp; </span>
                            <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;insights</span>
                            <span className="carousel-words">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;algorithm discovery&nbsp;&nbsp; </span>
                        </span>
                    </p>
                    <p className="leadblock-subtitle">Watch out! This website is under construction.</p>
                    <a href="/about" className="leadblock-link">VIEW PROJECTS &rarr;</a>
                </div>
            </div>

            <section style={{ backgroundColor: 'white', textAlign: 'center', paddingTop: '7%', paddingBottom: '7%', paddingLeft: '0px', paddingRight: '0px' }}>
                <img decoding="async" width="50" height="45" src="/S1.png" alt="Logo" loading="lazy" />
                <div style={{ color: 'black' }}>
                    <section className="main-description">
                        <strong>Scott</strong> is a computational biologist, founder, data scientist, neuroscience researcher, ML practitioner, and full stack developer who loves building complex projects.
                    </section>
                </div>
            </section>
            <FlowFieldAnimation />
            <section className="section-with-background" id="visualization-container" style={{ width: '100%', height: '800px', position: 'relative' }} />

            <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: -1 }}>
                    <ParallaxText3D />
                </div>
                <section ref={sectionRef} style={{
                    color: 'white',
                    textAlign: 'center',
                    padding: '80px 0',
                    position: 'relative',
                    zIndex: 1,
                }}>
                    <div style={{ position: 'relative', zIndex: 5 }}>
                        <h2><b>Uncovering biological insights</b></h2>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default PolyCypherPage;
