import React, { Component } from 'react';
import p5 from 'p5';

class FlowFieldAnimation extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myP5 = null;
    this.particles = [];
    this.flowField = [];
    this.scale = 10;
    this.cols = 0;
    this.rows = 0;
    this.textPaths = [];
    this.font = null;
    this.zoff = 0;
    this.fontLoaded = false;
  }

  componentDidMount() {
    this.myP5 = new p5(this.sketch, this.myRef.current);
  }

  componentWillUnmount() {
    this.myP5.remove();
  }

  sketch = (p) => {
    p.preload = () => {
      this.font = p.loadFont('Roboto.ttf', () => {
        this.fontLoaded = true;
      });
    };

    p.setup = () => {
    p.createCanvas(p.windowWidth, (0.98*document.body.scrollHeight));
    p.canvas.style.position = 'absolute';
    p.canvas.style.zIndex = '-10';
    p.clear(); // This makes the background transparent
      this.cols = p.floor(p.width / this.scale);
      this.rows = p.floor(p.height / this.scale);
      p.colorMode(p.HSB, 360, 100, 100, 100);
    };

    p.windowResized = () => {
        p.resizeCanvas(p.windowWidth, p.windowHeight*0.1);
        // Recalculate cols and rows based on new size
        this.cols = p.floor(p.width / this.scale);
        this.rows = p.floor(p.height / this.scale);
    };

    p.draw = () => {
      p.background(0, 3);

      if (this.fontLoaded) {
        this.initializeParticles(p);
        this.calculateFlowField(p);

        for (let particle of this.particles) {
            particle.behaviors(p, this.flowField);
            particle.avoidCards(p, this.props.cardPositions);
            particle.avoidCorners(p, this.props.cornerPositions);
            particle.run(p, this.flowField);
            particle.update(p);
            particle.edges(p);
            particle.show(p);
        }
      }
    };

    this.initializeParticles = (p) => {
      if (this.particles.length === 0 && this.fontLoaded) {
        let points = this.font.textToPoints('PolyCypher', 0, 0, 0, {
          sampleFactor: 0
        });

        let pathLength = 100;
        for (let i = 0; i < points.length; i += pathLength) {
          let path = [];
          for (let j = 0; j < pathLength && i + j < points.length; j++) {
            path.push(p.createVector(points[i + j].x, points[i + j].y));
          }
          this.textPaths.push(path);
        }

        for (let path of this.textPaths) {
          let particle = new Particle(path[0].x, path[0].y, true, path, p, this.scale, this.cols);
          this.particles.push(particle);
        }

        for (let i = 0; i < 1000; i++) {
          this.particles.push(new Particle(p.random(p.width), p.random(p.height), false, [], p, this.scale, this.cols));
        }
      }
    };

    this.calculateFlowField = (p) => {
      let yoff = 0;
      for (let y = 0; y < this.rows; y++) {
        let xoff = 0;
        for (let x = 0; x < this.cols; x++) {
          let index = x + y * this.cols;
          let angle = p.noise(xoff, yoff, this.zoff) * p.TWO_PI * 4;
          let v = p5.Vector.fromAngle(angle);
          v.setMag(2);
          this.flowField[index] = v;
          xoff += 0.1;
        }
        yoff += 0.1;
      }
      this.zoff += 0.01;
    };
  };

  render() {
    return <div ref={this.myRef} style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}></div>;
  }
}

class Particle {
  constructor(x, y, isTextParticle, path, p, scale, cols) {
    this.home = p.createVector(x, y); // Home position is the text point
    this.pos = p.createVector(x, y);
    this.vel = p.createVector();
    this.acc = p.createVector();
    this.prevPos = this.pos.copy(); // Ensure prevPos is initialized here
    this.isTextParticle = isTextParticle;
    this.maxSpeed = isTextParticle ? 0.8 : p.random(0.2, 0.7);
    this.maxForce = isTextParticle ? 10 : p.random(0.5, 3);
    this.color = isTextParticle ? p.color(255, 0, 0) : p.color(0, 0, 100);
    this.color = !isTextParticle ? p.color(200, 200, 50, 100) : p.color(200, 200, 50, 25);
    this.path = path;
    this.scale = scale;
    this.cols = cols;

    this.baseMaxSpeed = this.maxSpeed; // Store the initial maxSpeed as the base max speed
    this.dynamicSpeedAdjustment = 0; // Additional speed due to dynamic factors
  }

  avoidCorners(p, cornerPositions) {
    cornerPositions.forEach(corner => {
      let cornerPoint = p.createVector(corner.x, corner.y);
      let d = p.dist(this.pos.x, this.pos.y, cornerPoint.x, cornerPoint.y);
      if (d < 20) {  // Define a suitable threshold
        let strength = -20 / (d); // Define the strength
        let dir = p5.Vector.sub(this.pos, cornerPoint);
        dir.normalize();
        dir.mult(strength);
        this.applyForce(dir);
      }
    });
  }

  run(p) {
    // Reset dynamic speed adjustment at the beginning of each update cycle
    this.dynamicSpeedAdjustment = 0;

    this.update(p);
    // Ensure updatePrev is called just after updating the position and before drawing the line.
    this.updatePrev(p);
    this.edges(p);
    this.show(p);

    // Apply the dynamic speed adjustment
    this.maxSpeed = p.min(this.baseMaxSpeed + this.dynamicSpeedAdjustment, 5); // Ensure maxSpeed does not exceed an upper limit

    this.vel.add(this.acc);
    this.vel.limit(this.maxSpeed);
    this.pos.add(this.vel);
    this.acc.mult(0);
  }

  behaviors(p, flowField) {
    if (this.isTextParticle) {
      this.wander(p);
      this.returnToHome(p);
      this.avoidMouse(p);
    } else {
      this.updateColor(p);
      this.avoidMouse(p);
      this.follow(flowField, p);
    }
  } 


  isInsideArea(area, p) {
    return (
      this.pos.x > area.x && this.pos.x < area.x + area.width &&
      this.pos.y > area.y && this.pos.y < area.y + area.height
    );
  }
  

  shimmerEffect(p) {
    let mouse = p.createVector(p.mouseX, p.mouseY);
    let d = p.dist(p.mouseX, p.mouseY, this.pos.x, this.pos.y);
    let force = (700 / (d * d)); // Inverse square law

    // Map the force to a range for the hue
    let hue = p.map(force, 0, 0.01, 240, 345, true); // Blue to Pink-Red range

    // Adjust saturation and brightness
    let saturation = 95;
    let brightness = 100;

    this.color = p.color(hue, saturation, brightness);
  }

  updateColor(p) {
    let d = p.dist(p.mouseX, p.mouseY, this.pos.x, this.pos.y);

    if (d < 300) { // Close to the mouse
      let force = 100 / (d * d); // Inverse square law
      let hue = p.map(force, 0, 0.01, 240, 320, true); // Blue to Pink-Red range

      this.color = p.color(hue, 95, 100);
    } else { // Far from the mouse
      let hue = p.map(d, 0, p.width, 220, 350);
      this.color = p.color(hue, 80, 100);
    }
  }

  wander(p) {
    let wanderForce = p5.Vector.random2D().setMag(this.maxForce);
    this.applyForce(wanderForce);
  }

  returnToHome(p) {
    let homeForce = p5.Vector.sub(this.home, this.pos);
    homeForce.setMag(this.maxForce);
    this.applyForce(homeForce);
  }

  follow(vectors, p) {
    let x = p.floor(this.pos.x / this.scale);
    let y = p.floor(this.pos.y / this.scale);
    let index = x + y * this.cols;
    let force = vectors[index];
    this.applyForce(force);
  }

  avoidMouse(p) {
    let mouse = p.createVector(p.mouseX, p.mouseY);
    let dir = p5.Vector.sub(this.pos, mouse);
    let d = dir.mag();
    let strength = 25000 / (d * d); // Inverse square law
    dir.normalize();
    dir.mult(strength);

    this.applyForce(dir);
    this.adjustSpeedForInteraction(dir, p); // Apply dynamic speed adjustment for mouse interaction
  }

  arrive(target, p) {
    let desired = p5.Vector.sub(target, this.pos);
    let d = desired.mag();
    let speed = this.maxSpeed;
    if (d < 100) {
      speed = p.map(d, 0, 100, 0, this.maxSpeed);
    }
    desired.setMag(speed);
    let steer = p5.Vector.sub(desired, this.vel);
    steer.limit(this.maxForce);
    return steer;
  }


  // Call this method for interactions like mouse movement
  adjustSpeedForInteraction(force, p) {
    let forceMag = force.mag();
    this.dynamicSpeedAdjustment = p.min(forceMag, 4); // Adjust the upper limit as needed
  }


  applyForce(force) {
    this.acc.add(force);
  }

  update(p) {
    this.vel.add(this.acc);
    this.vel.limit(this.maxSpeed);
    this.pos.add(this.vel);
    this.acc.mult(0);
  }

  updatePrev(p) {
    this.prevPos.x = this.pos.x;
    this.prevPos.y = this.pos.y;
  }

  show(p) {
    let distance = p.dist(this.pos.x, this.pos.y, p.mouseX, p.mouseY);
    let brightnessAdjustment = p.constrain(1000000 / (distance * distance), 0.3, 0.65); // Inverse square law for brightness adjustment

    let adjustedBrightness = p.brightness(this.color) * brightnessAdjustment;
    let adjustedColor = p.color(p.hue(this.color), p.saturation(this.color), adjustedBrightness);

    p.stroke(adjustedColor);
    p.strokeWeight(this.isTextParticle ? 6 : 5);
    p.line(this.pos.x, this.pos.y, this.prevPos.x, this.prevPos.y);
  }

  edges(p) {
    if (this.pos.x > p.width) {
      this.pos.x = 0;
      this.updatePrev(p);
    }
    if (this.pos.x < 0) {
      this.pos.x = p.width;
      this.updatePrev(p);
    }
    if (this.pos.y > p.height) {
      this.pos.y = 0;
      this.updatePrev(p);
    }
    if (this.pos.y < 0) {
      this.pos.y = p.height;
      this.updatePrev(p);
    }
  }

  avoidCards(p, cardPositions) {
    cardPositions.forEach(card => {
      let cardRect = {
        x: card.x,
        y: card.y,
        width: card.width,
        height: card.height
      };
  
      // Check if the particle is inside the rectangle
      if (this.isInsideArea(cardRect, p)) {
        // Calculate direction vector from particle to rectangle center
        let center = p.createVector(cardRect.x + cardRect.width / 2, cardRect.y + cardRect.height / 2);
        let dir = p5.Vector.sub(center, this.pos);
  
        // Calculate repulsion force based on distance
        let d = dir.mag();
        let strength = 250000000 / (d * d); // Adjust this value as needed
        dir.normalize();
        dir.mult(-strength); // Repel away from the center
        this.applyForce(dir);
      }
    })
  }
  



}

export default FlowFieldAnimation;