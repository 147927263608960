import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import perlin from 'perlin-noise';

const FlowFieldAnimation = () => {
    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const width = container.clientWidth;
        const height = container.clientHeight;

        const svg = d3.select(container)
            .append('svg')
            .attr('width', width)
            .attr('height', height)
            .style('background', 'linear-gradient(to bottom, #000428, #004e92)');

        const numParticles = 100;
        const numPatrolParticles = 10;
        const noiseScale = 5;
        const noiseSpeed = 0.002;
        const baseParticleSpeed = 2;
        const margin = 50;

        const colorPalette = d3.scaleSequential()
            .domain([0, numParticles])
            .interpolator(d3.interpolateYlGnBu);

        let particles = d3.range(numParticles).map((_, i) => ({
            x: margin + Math.random() * (width - margin * 2),
            y: margin + Math.random() * (height - margin * 2),
            vx: 0,
            vy: 0,
            color: colorPalette(i),
            trail: [],
            size: Math.random() * 2 + 1,
            speed: Math.random() * 2 + 0.5,
            trailLength: Math.random() * 300 + 50,
            repelForce: Math.random() * 0.3 + 0.1,
            jitterStrength: Math.random() * 0.6 + 0.1
        }));

        // Border patrol particles slightly outside the border
        let patrolParticles = d3.range(numPatrolParticles).map(() => {
            const side = Math.floor(Math.random() * 4);
            let x, y, vx, vy;

            if (side === 0) { // Top side
                x = Math.random() * width;
                y = margin - 10;
                vx = 1;
                vy = 0;
            } else if (side === 1) { // Right side
                x = width - margin + 10;
                y = Math.random() * height;
                vx = 0;
                vy = 1;
            } else if (side === 2) { // Bottom side
                x = Math.random() * width;
                y = height - margin + 10;
                vx = -1;
                vy = 0;
            } else { // Left side
                x = margin - 10;
                y = Math.random() * height;
                vx = 0;
                vy = -1;
            }

            return {
                x,
                y,
                vx,
                vy,
                color: 'purple',
                trail: [],
                size: 5,
                speed: 8,
                trailLength: 5,
                repelRadius: 1000, // Repel radius
                repelForce: 300 // Extreme repelling force
            };
        });

        particles = particles.concat(patrolParticles);
        let noise = perlin.generatePerlinNoise(Math.ceil(width / noiseScale), Math.ceil(height / noiseScale));

        const particleElements = svg.selectAll('g')
            .data(particles)
            .join('g');

        particleElements.append('circle')
            .attr('r', d => d.size)
            .attr('fill', d => d.color)
            .attr('opacity', 0.8);

        particleElements.append('path')
            .attr('fill', 'none')
            .attr('stroke', d => d.color)
            .attr('stroke-width', d => d.size * 0.5);

        const updateParticle = (particle, isPatrol) => {
            if (!isPatrol) {
                const noiseX = Math.floor(particle.x / noiseScale);
                const noiseY = Math.floor(particle.y / noiseScale);
                const noiseValue = noise[noiseY * Math.ceil(width / noiseScale) + noiseX];
                const angle = noiseValue * Math.PI * 4;

                particle.vx = Math.cos(angle) * particle.speed * baseParticleSpeed;
                particle.vy = Math.sin(angle) * particle.speed * baseParticleSpeed;

                // Add significant random jitter to the particle movement
                particle.vx += (Math.random() - 0.5) * particle.jitterStrength;
                particle.vy += (Math.random() - 0.5) * particle.jitterStrength;

                // Apply repulsion from patrol particles
                patrolParticles.forEach(patrol => {
                    const dx = particle.x - patrol.x;
                    const dy = particle.y - patrol.y;
                    const distance = Math.sqrt(dx * dx + dy * dy);
                    if (distance < patrol.repelRadius) {
                        const repelForce = patrol.repelForce / (distance * distance + 1);
                        particle.vx += dx * repelForce;
                        particle.vy += dy * repelForce;
                    }
                });

                particle.x += particle.vx;
                particle.y += particle.vy;

                // Ensure particles do not go off the screen
                if (particle.x < margin) particle.x = margin;
                if (particle.x > width - margin) particle.x = width - margin;
                if (particle.y < margin) particle.y = margin;
                if (particle.y > height - margin) particle.y = height - margin;

                particle.trail.unshift({ x: particle.x, y: particle.y });
                if (particle.trail.length > particle.trailLength) {
                    particle.trail.pop();
                }

                // Softly repel particles from the edges
                if (particle.x < margin) particle.vx += particle.repelForce;
                if (particle.x > width - margin) particle.vx -= particle.repelForce;
                if (particle.y < margin) particle.vy += particle.repelForce;
                if (particle.y > height - margin) particle.vy -= particle.repelForce;

                // Swarm behavior: particles are attracted to each other
                particles.forEach(other => {
                    if (other !== particle && other.color !== 'purple') {
                        const dx = other.x - particle.x;
                        const dy = other.y - particle.y;
                        const distance = Math.sqrt(dx * dx + dy * dy);
                        const force = 1 / (distance + 1); // attraction force decreases with distance

                        particle.vx += dx * force * 0.001;
                        particle.vy += dy * force * 0.001;
                    }
                });
            } else {
                // Move patrol particles along the edges
                particle.x += particle.vx * particle.speed;
                particle.y += particle.vy * particle.speed;

                // Ensure patrol particles stay outside the border
                if (particle.x < margin - 10) particle.vx = Math.abs(particle.vx);
                if (particle.x > width - margin + 10) particle.vx = -Math.abs(particle.vx);
                if (particle.y < margin - 10) particle.vy = Math.abs(particle.vy);
                if (particle.y > height - margin + 10) particle.vy = -Math.abs(particle.vy);

                particle.trail.unshift({ x: particle.x, y: particle.y });
                if (particle.trail.length > particle.trailLength) {
                    particle.trail.pop();
                }
            }
        };

        const animateParticles = () => {
            particles.forEach(particle => {
                updateParticle(particle, particle.color === 'purple');
            });

            particleElements.select('circle')
                .attr('cx', d => d.x)
                .attr('cy', d => d.y);

            particleElements.select('path')
                .attr('d', d => {
                    const path = d3.path();
                    if (d.trail.length > 0) {
                        path.moveTo(d.trail[0].x, d.trail[0].y);
                        for (let i = 1; i < d.trail.length; i++) {
                            path.lineTo(d.trail[i].x, d.trail[i].y);
                        }
                    }
                    return path.toString();
                })
                .attr('stroke-opacity', (d, i) => {
                    const opacityDecay = d3.easeQuadOut(i / (d.trail.length - 1));
                    return opacityDecay;
                });

            requestAnimationFrame(animateParticles);
        };

        const updateNoisePeriodically = () => {
            noise = perlin.generatePerlinNoise(Math.ceil(width / noiseScale), Math.ceil(height / noiseScale));
            setTimeout(updateNoisePeriodically, 5000); // Update noise every 5 seconds
        };

        animateParticles();
        updateNoisePeriodically();

        return () => {
            svg.remove();
        };
    }, []);

    return <div ref={containerRef} style={{ width: '100%', height: '100%', position: 'absolute' }} />;
};

export default FlowFieldAnimation;
