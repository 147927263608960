// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { CognitoProvider } from './CognitoContext';
import WaitingList from './WaitingList';
import Example from './Example';
import Dashboard from './Dashboard';
//import Login from './Login';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import './App.css';
import FlowFieldAnimation from './flow';
import Science from './Science'


import Header from './Header';
import Footer from './Footer';


import {
  ClinicalTrialEnrichment, PolygenicGameteDonorScreening,
  PolygenicModeling, MachineLearning, Partnerships, Accessibility, PolygenicDiseases,
} from './PlaceholderPages';


import {
  HowGWASWorks, CurrentGWASTrends, PolygenicScoresFromGWAS, ImprovingPolygenicScores,
  MultiTraitApproaches, BayesianApproaches, DiseasePRSStudies, TermsAndConcepts,
  ClinicalPerception, CaveatsAndLimitations
} from './SciencePages';

import {
  CircadianRhythm,
  NeurodegenerativeDisease,
  MultiModelBenchmarking,
  Psychoplastogens,
  PolygenicAI,
  BioinformaticsConsulting,
  SoftwareDevelopment,
  Tutoring,
  ScientificAutomation,
  DataEngineering,
} from './PlaceholderComponents';



const App = () => {
  return (
    <Router>
      <CognitoProvider>
        <div id="content">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/example-dev" element={<Example />} />
            <Route path="/login" element={<WaitingList />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/polygenic-health-report" element={<Example />} />
            <Route path="/clinical-trial-enrichment" element={<ClinicalTrialEnrichment />} />
            <Route path="/polygenic-gamete-donor-screening" element={<PolygenicGameteDonorScreening />} />
            <Route path="/polygenic-modeling" element={<PolygenicModeling />} />
            <Route path="/ML" element={<MachineLearning />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/science" element={<Science />} />
            <Route path="/accessibility" element={<Accessibility />} />
            <Route path="/polygenic-diseases" element={<PolygenicDiseases />} />
            <Route path="/flow" element={<FlowFieldAnimation />} />
            <Route path="/how-gwas-works" element={<HowGWASWorks />} />
            <Route path="/current-gwas-trends" element={<CurrentGWASTrends />} />
            <Route path="/polygenic-scores-gwas" element={<PolygenicScoresFromGWAS />} />
            <Route path="/improving-polygenic-scores" element={<ImprovingPolygenicScores />} />
            <Route path="/multi-trait-approaches" element={<MultiTraitApproaches />} />
            <Route path="/bayesian-approaches" element={<BayesianApproaches />} />
            <Route path="/disease-prs-studies" element={<DiseasePRSStudies />} />
            <Route path="/terms-concepts" element={<TermsAndConcepts />} />
            <Route path="/clinical-perception" element={<ClinicalPerception />} />
            <Route path="/caveats-limitations" element={<CaveatsAndLimitations />} />
            <Route path="/sleep" element={<CircadianRhythm />} />
            <Route path="/disease" element={<NeurodegenerativeDisease />} />
            <Route path="/benchmark" element={<MultiModelBenchmarking />} />
            <Route path="/psychoplastogens" element={<Psychoplastogens />} />
            <Route path="/polygenic" element={<PolygenicAI />} />
            <Route path="/consulting" element={<BioinformaticsConsulting />} />
            <Route path="/software" element={<SoftwareDevelopment />} />
            <Route path="/tutoring" element={<Tutoring />} />
            <Route path="/automation" element={<ScientificAutomation />} />
            <Route path="/data" element={<DataEngineering />} />
          </Routes>
          <Footer />
        </div>
      </CognitoProvider>
    </Router>
  );
};

export default App;